*{
  padding: 0;
  margin: 0;
  -webkit-touch-callout:none;/*系统默认菜单被禁用*/
  -webkit-user-select:none;/*webkit浏览器*//*早期浏览器*//*火狐*//*IE10*/
  user-select:none;
  font-size: 14px;
}

ul, ol, li, dl, dd {
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style: none outside none;
}

i{
  font-style: normal;
}

.App {
  width: 100vw;
  height: 100vh;
  text-align: center;
  overflow-y: scroll;
  padding-right: 0;
}
html,body{
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media all and (max-width: 768px) {
    *{
      font-size: 16px;
    }
}