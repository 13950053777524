.SwiperBanner {
  width: 900px;
  height: 180px;
  margin: 0 auto;
  padding: 5px;
  box-sizing: border-box;
}
.swiperContainer {
  width: 100%;
  height: 170px;
  margin: 0 auto;
  box-sizing: border-box;
  cursor: pointer;
}
.swiperContainer .img {
  width: 890px;
  height: 170px;
}
@media all and (max-width: 768px) {
  .SwiperBanner {
    width: 100%;
    height: 170px;
    padding: 5px 0;
  }
  .SwiperBanner .img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
}
