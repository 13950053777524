.tabel {
  width: 100%;
  height: auto;
  border: solid 1px #ddd;
}
.tabel tr:nth-child(1) td {
  font-weight: bold;
  font-size: 13px;
  color: #000;
  font-family: 'Microsoft Yahei';
}
.tabel td {
  height: 30px;
  color: #606266;
  font-size: 13px;
}
.tabel td span {
  display: inline-block;
  background-color: #2265fa;
  color: #fff;
  width: 35px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 7px;
  font-size: 12px;
}
.tabel td span.da,
.tabel td span.shuang,
.tabel td span.dashuang,
.tabel td span.xiaoshuang {
  background-color: #ff2b30;
}
