.logoHeader {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  height: 44px;
  top: 0px;
  box-shadow: rgb(181 181 181) 0px 0px 44px;
  padding: 0px 0px;
}
.logoHeader .content {
  width: 900px;
  margin: 0 auto;
  height: 44px;
  line-height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;
  box-sizing: border-box;
}
.logoHeader .content .logo {
  width: 170px;
  height: 38px;
  background: url("../../static/image/jblogo.png") no-repeat center center;
  background-size: 170px 38px;
}
@media all and (max-width: 768px) {
  .content {
    width: 100%;
  }
}
