.canada28Online {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: #f6f5f5;
}
.canada28Online .latestIssue {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  height: 75px;
  align-items: center;
}
.canada28Online .latestIssue .img {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url("../../static/image/Canada/canada_logo.png") no-repeat center center;
  background-size: cover;
  margin-right: 10px;
}
.canada28Online .latestIssue .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.canada28Online .latestIssue .content .LotNumber {
  font-size: 16px;
}
.canada28Online .latestIssue .content .LotNumber .number {
  margin: 0 5px;
  color: #e63442;
  font-size: 15px;
}
.canada28Online .latestIssue .content .PhaseController {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.canada28Online .latestIssue .content .PhaseController span {
  width: 70px;
  height: 25px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  line-height: 23px;
}
.canada28Online .latestIssue .content .PhaseController button {
  width: 40px;
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  line-height: 23px;
  cursor: pointer;
  color: #7d7d7d;
}
.canada28Online .latestIssue .content .PhaseController button.add {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.canada28Online .latestIssue .content .PhaseController button.reduce {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.canada28Online .countdown {
  width: 100%;
  background-color: #fff;
  margin-top: 20px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.canada28Online .countdown .ShowCountDown {
  display: flex;
  align-items: center;
}
.canada28Online .countdown .ShowCountDown .nextTetx {
  font-size: 17px;
  color: #6c6c6c;
}
.canada28Online .countdown .ShowCountDown .countDownContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.canada28Online .countdown .ShowCountDown .countDownContent .Minute {
  display: inline-block;
  font-size: 33px;
  color: #ffffff;
  width: 50px;
  padding: 2px 0;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  transform: translateZ(0);
  background: rgba(0, 0, 0, 0) linear-gradient(#fb4755 50%, #e63442 50%) repeat scroll 0% 0%;
}
.canada28Online .countdown .ShowCountDown .countDownContent .MinuteText {
  margin: 0 10px;
  font-size: 17px;
  color: #6c6c6c;
}
.canada28Online .countdown .ShowCountDown .countDownContent .second {
  display: inline-block;
  font-size: 33px;
  color: #ffffff;
  width: 50px;
  padding: 2px 0;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  transform: translateZ(0);
  background: rgba(0, 0, 0, 0) linear-gradient(#fb4755 50%, #e63442 50%) repeat scroll 0% 0%;
}
.canada28Online .countdown .ShowCountDown .countDownContent .secondText {
  margin: 0 10px;
  font-size: 17px;
  color: #6c6c6c;
  margin-right: 0;
}
.canada28Online .countdown .showLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.canada28Online .countdown .showLoading .text {
  color: red;
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
}
.canada28Online .countdown .showLoading .loadingImg {
  width: 50px;
  height: 56px;
  display: inline-block;
  background: url("../../static/image/Canada/kjlogo.gif") no-repeat center center;
  background-size: cover;
}
.canada28Online .newOpenResult {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  background-color: #fff;
  margin-top: 20px;
}
.canada28Online .newOpenResult .ball {
  background-color: #13b5b1;
  color: #ffffff;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  font-size: 25px;
}
.canada28Online .newOpenResult .tag {
  margin: 0 5px;
}
.canada28Online .newOpenResult .toatl {
  background-color: red;
  color: #ffffff;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 7px;
  font-size: 25px;
}
.canada28Online .newOpenResult .size {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ff2b30;
  color: #fff;
  margin-left: 10px;
}
.canada28Online .newOpenResult .size.small {
  background: #2265fa;
}
.canada28Online .newOpenResult span:last-child {
  margin-left: 3px;
}
.canada28Online .mipai {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-top: 20px;
}
.canada28Online .mipai .button {
  background-color: red;
  display: block;
  color: #ffffff;
  border-radius: 5px;
  width: 200px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 15px;
  margin: 0px auto;
  cursor: pointer;
}
.canada28Online .mipaiContainer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #484545;
  z-index: 20000;
}
.canada28Online .mipaiContainer .content {
  width: 350px;
  height: 300px;
  background: url("../../static/image/Canada/gglbj.png") no-repeat center center;
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.canada28Online .mipaiContainer .content .lotNumberDesc {
  display: flex;
  flex-direction: column;
  padding-top: 22px;
  height: 52px;
  align-items: flex-end;
}
.canada28Online .mipaiContainer .content .lotNumberDesc .top {
  color: #fff;
  margin-right: 10px;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.canada28Online .mipaiContainer .content .lotNumberDesc .top span {
  color: #ffbca8;
  font-size: 20px;
  font-weight: 500;
  margin: 0 5px;
}
.canada28Online .mipaiContainer .content .lotNumberDesc .bottom {
  margin-right: 10px;
}
.canada28Online .mipaiContainer .content .lotNumberDesc .bottom .countDownContent {
  display: flex;
  color: #fff;
}
.canada28Online .mipaiContainer .content .lotNumberDesc .bottom .countDownContent span {
  font-size: 18px;
}
.canada28Online .mipaiContainer .content .lotNumberDesc .bottom .countDownContent .Minute {
  color: #ffbca8;
  margin: 0 5px;
}
.canada28Online .mipaiContainer .content .lotNumberDesc .bottom .countDownContent .second {
  color: #ffbca8;
  margin: 0 5px;
}
.canada28Online .mipaiContainer .content .lotNumberDesc .bottom .showLoading span {
  color: #fff;
  font-size: 18px;
}
.canada28Online .mipaiContainer .content .guaguale {
  width: 300px;
  height: 105px;
  background-color: #fff;
  position: relative;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: red;
  font-weight: 500;
}
.canada28Online .mipaiContainer .content .guaguale span {
  width: 50px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 2px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
}
.canada28Online .mipaiContainer .content .guaguale #cvs {
  position: absolute;
  left: 0;
  top: 0;
}
.canada28Online .mipaiContainer .content .btnGroup {
  margin-top: 20px;
}
.canada28Online .mipaiContainer .content .btnGroup .refreshBtn {
  display: inline-block;
  margin: 5px 10px 0px 10px;
  font-size: 18px;
  width: 80px;
  height: 40px;
  line-height: 40px;
  border-radius: 7px;
  cursor: pointer;
  background-color: #ffe258;
}
.canada28Online .mipaiContainer .content .btnGroup .closeBtn {
  display: inline-block;
  margin: 5px 10px 0px 10px;
  font-size: 18px;
  width: 80px;
  height: 40px;
  line-height: 40px;
  border-radius: 7px;
  cursor: pointer;
  background-color: #ffe258;
  background-color: #ccc;
}
