.tabel {
  width: 100%;
  height: auto;
  border: solid 1px #ddd;
  border-width: 0px 1px 1px 0px;
}
.tabel tr:nth-child(1) {
  background-color: #f5f6f8;
}
.tabel tr:nth-child(1) td {
  font-weight: bold;
  font-size: 15px;
}
.tabel tr {
  display: flex;
}
.tabel td {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #ddd;
  border-width: 1px 0px 0px 1px;
  height: 30px;
  color: #606266;
  font-size: 15px;
}
@media all and (max-width: 768px) {
  td {
    line-height: 15px;
  }
}
