.tabel {
  width: 100%;
  height: auto;
  border: solid 1px #ddd;
  border-width: 0px 1px 1px 0px;
}
.tabel tr:nth-child(1) {
  background-color: #f5f6f8;
}
.tabel tr:nth-child(1) td {
  font-weight: bold;
  font-size: 15px;
}
.tabel tr {
  display: flex;
}
.tabel td {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #ddd;
  border-width: 1px 0px 0px 1px;
  height: 40px;
  color: #606266;
  font-size: 15px;
}
.tabel td span {
  font-size: 16px;
  font-weight: bold;
}
.tabel td .Ball {
  margin-right: 10px;
  color: #fff;
  width: 30px;
  border-radius: 50%;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
}
@media all and (max-width: 768px) {
  .Ball {
    margin: 0 5px!important;
  }
}
