.layout {
  width: 900px;
  padding: 0 5px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 10px;
}
@media all and (max-width: 768px) {
  .layout {
    width: 100%;
    box-sizing: border-box;
  }
}
