.hongkongSix {
  width: 900px;
  padding: 0 5px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 20px;
}
.hongkongSix .hongkongSixHeaderTitleBox {
  border: 1px solid #ddd;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSix {
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #ddd;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSix span {
  color: red;
  margin: 0 10px;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSix.even {
  background-color: #fff;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSix.oddNumber {
  background-color: #f6f6f6;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSixOpenResult {
  border-bottom: 1px solid #ddd;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSixOpenResult.even {
  background-color: #fff;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSixOpenResult.oddNumber {
  background-color: #f6f6f6;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSixOpenResult .ulBox {
  display: flex;
  flex-direction: row;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSixOpenResult .ulBox li {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSixOpenResult .ulBox li .openBall {
  color: #000;
  width: 40px;
  height: 40px;
  line-height: 32px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSixOpenResult .ulBox li .openBall.redBall {
  background: url(../../static/image/HongKongMarkSix/redBall.png) no-repeat center center;
  background-size: cover;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSixOpenResult .ulBox li .openBall.blueBall {
  background: url(../../static/image/HongKongMarkSix/blueBall.png) no-repeat center center;
  background-size: cover;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSixOpenResult .ulBox li .openBall.greenBall {
  background: url(../../static/image/HongKongMarkSix/greenBall.png) no-repeat center center;
  background-size: cover;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSixOpenResult .ulBox li .Attributes {
  margin-top: 10px;
}
.hongkongSix .hongkongSixHeaderTitleBox .newMarkSixOpenResult .ulBox li .Attributes i:nth-child(2) {
  color: red;
  font-weight: bold;
}
.hongkongSix .hongkongSixHeaderTitleBox .nextOpenResultTime {
  border-bottom: 1px solid #ddd;
  line-height: 32px;
  cursor: pointer;
}
.hongkongSix .hongkongSixHeaderTitleBox .handlerNextOpenResultBtn {
  border-bottom: 1px solid #ddd;
  line-height: 32px;
  cursor: pointer;
  color: red;
  border-bottom: none;
}
.hongkongSix .hongkongSixHeaderTitleBox .handlerNextOpenResultBtn.borderBot {
  border-bottom: 1px solid #ddd;
}
.hongkongSix .hongkongSixHeaderTitleBox .preRecordParent {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
@media all and (max-width: 768px) {
  .hongkongSix {
    width: 100%;
    box-sizing: border-box;
    font-size: 16px!important;
  }
}
