.container {
  width: 100%;
  overflow-x: auto;
}
.container .tabel {
  width: 100%;
  height: auto;
  border: solid 1px #ddd;
  border-width: 0px 1px 1px 0px;
}
.container .tabel tr:nth-child(1) {
  background-color: #f5f6f8;
}
.container .tabel tr:nth-child(1) td {
  font-weight: bold;
  font-size: 15px;
}
.container .tabel td {
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  border: solid 1px #ddd;
  border-width: 1px 0px 0px 1px;
  height: 40px;
  color: #606266;
  font-size: 15px;
  padding: 5px 10px;
}
.container .tabel td span {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #fff;
  margin-right: 5px;
  text-shadow: 0px 0px 3px #000000;
}
.container .tabel td span:last-child {
  margin-right: 0;
}
.container .tabel td span.span01 {
  background: #e6de00;
}
.container .tabel td span.span02 {
  background: #0092dd;
}
.container .tabel td span.span03 {
  background: #4b4b4b;
}
.container .tabel td span.span04 {
  background: #ff7600;
}
.container .tabel td span.span05 {
  background: #17e2e5;
}
.container .tabel td span.span06 {
  background: #5234ff;
}
.container .tabel td span.span07 {
  background: #bfbfbf;
}
.container .tabel td span.span08 {
  background: #ff2600;
}
.container .tabel td span.span09 {
  background: #780b00;
}
.container .tabel td span.span10 {
  background: #07bf00;
}
.container .tabel td span.bg7f8ab0 {
  background: #7f8ab0;
}
.container .tabel td span.bgf9982e {
  background: #f9982e;
}
.container .tabel td font {
  margin-right: 10px;
}
.container .tabel td font:last-child {
  margin-right: 0;
}
.container .C184dd5 {
  color: #184dd5;
}
.container .Cf12d35 {
  color: #f12d35;
}
