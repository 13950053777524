.TypeButton {
  width: 100%;
}
.TypeButton ul {
  display: flex;
  flex-wrap: wrap;
}
.TypeButton ul li {
  flex: 0 1 50%;
  background-color: #fff;
  border: 1px solid #ddd;
  box-sizing: border-box;
  height: 38px;
  line-height: 36px;
  cursor: pointer;
}
.TypeButton ul li.active {
  border-bottom: 3px solid #4687ff;
}
