.RecordContainer {
  width: 100%;
  background-color: #fff;
  padding-top: 5px;
  padding-bottom: 100px;
}
.RecordContainer .buttonGroup {
  display: flex;
}
.RecordContainer .buttonGroup div {
  flex: 1 1;
  height: 25px;
  line-height: 25px;
  background-color: #335397;
  position: relative;
  display: flex;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  font-size: 13px;
  align-items: center;
}
.RecordContainer .buttonGroup div.cuishi {
  background-color: #00c7f7;
}
.RecordContainer .buttonGroup div.yuce {
  background-color: #00aa7f;
}
.RecordContainer .buttonGroup div .resultIcon {
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url("../../static/image/Canada/result.png") no-repeat;
  background-size: 13px 13px;
  margin-right: 5px;
}
.RecordContainer .buttonGroup div .cuishiIcon {
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url("../../static/image/Canada/result.png") no-repeat;
  margin-right: 5px;
  background: url("../../static/image/Canada/zoushi.png") no-repeat;
  background-size: 13px 13px;
}
.RecordContainer .buttonGroup div .yuceIcon {
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url("../../static/image/Canada/result.png") no-repeat;
  margin-right: 5px;
  background: url("../../static/image/Canada/yuce.png") no-repeat;
  background-size: 13px 13px;
}
.RecordContainer .buttonGroup div .resultTag {
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 0px;
  height: 0px;
  transform: translateX(-50%);
}
.RecordContainer .buttonGroup div .resultTag.active {
  border-top: 10px solid #335397;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.RecordContainer .buttonGroup div .cuishiTag {
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 0px;
  height: 0px;
  transform: translateX(-50%);
}
.RecordContainer .buttonGroup div .cuishiTag.active {
  border-top: 10px solid #335397;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.RecordContainer .buttonGroup div .cuishiTag.active {
  border-top: 10px solid #00c7f7;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.RecordContainer .buttonGroup div .yuceTag {
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 0px;
  height: 0px;
  transform: translateX(-50%);
}
.RecordContainer .buttonGroup div .yuceTag.active {
  border-top: 10px solid #335397;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.RecordContainer .buttonGroup div .yuceTag.active {
  border-top: 10px solid #00aa7f;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.RecordContainer .resultContainer {
  margin-top: 10px;
}
